import infra from "@frontend/allspark/infra/index_beriberu-lk.js";

const $infra = infra.infra;

export default {
  getInfo({ commit }) {
    return $infra.jsonrpc.getInfo().then((res) => {
      if (!Array.isArray((res || {}).active_loans)) {
        throw res;
      }

      commit("setInfo", res);
    });
  },
  logout() {
    return $infra.jsonrpc.logout();
  },
  getDocumentsLoan({ commit, state }) {
    const id = (((state.info || {}).active_loans || {})[0] || {}).ext_id;

    $infra.jsonrpc
      .getAllDocuments({
        entity_id: id,
      })
      .then((res) => {
        commit("setLoanDocuments", [
          ...(res.loan || []).reduce((acc, cur) => {
            if (cur.document_template_type !== "notification") {
              cur.typeDownload = "loan";
              acc.push(cur);
            }
            return acc;
          }, []),
          ...(res.changes || []).reduce((acc, change) => {
            const docs = (change.documents || []).reduce((docsList, doc) => {
              if (doc.document_template_type !== "notification") {
                doc.typeDownload = "change";
                docsList.push(doc);
              }
              return docsList;
            }, []);
            return acc.concat(docs);
          }, []),
        ]);

        commit("setNotification", [
          ...(res.loan || []).reduce((acc, cur) => {
            if (cur.document_template_type === "notification") {
              cur.typeDownload = "loan";
              acc.push(cur);
            }
            return acc;
          }, []),
          ...(res.changes || []).reduce((acc, change) => {
            const docs = (change.documents || []).reduce((docsList, doc) => {
              if (doc.document_template_type === "notification") {
                doc.typeDownload = "change";
                docsList.push(doc);
              }
              return docsList;
            }, []);
            return acc.concat(docs);
          }, []),
        ]);

        commit("setDocumentsIdArchive", res.archive_data);
      });
  },
  getDocumentsProlongationFree({ commit, state }) {
    const id = (((state.info || {}).active_loans || {})[0] || {}).ext_id;

    $infra.jsonrpc
      .getDocuments({
        entity_id: id,
        type: "preview_change",
        meta: {
          code: "free_2",
          services: [],
        },
      })
      .then((res) => {
        commit(
          "setDocumentsProlongationFree",
          (res || []).map((item) => {
            item.typeDownload = "preview_change";
            return item;
          })
        );
      });
  },
  getDocumentsProlongation({ commit, state }, services) {
    const id = (((state.info || {}).active_loans || {})[0] || {}).ext_id;

    $infra.jsonrpc
      .getDocuments({
        entity_id: id,
        type: "preview_change",
        meta: {
          code: "paid_ld",
          services,
        },
      })
      .then((res) => {
        commit(
          "setDocumentsProlongation",
          (res || []).map((item) => {
            item.typeDownload = "preview_change";
            return item;
          })
        );
      });
  },
  getPayInfo({ commit }) {
    return $infra.jsonrpc.payInfo().then((res) => {
      commit("setPayInfo", res);
    });
  },
  getHistory({ commit }) {
    return $infra.jsonrpc
      .getOperationsHistory({
        limit: 100,
        offset: 0,
      })
      .then((res) => {
        commit("setHistory", res);
      })
      .catch((e) => {
        console.error(e);
        alert(JSON.stringify(e));
      });
  },
  getProfileInfo({ commit }) {
    return $infra.jsonrpc
      .getProfileInfo()
      .then((res) => {
        commit("setProfile", res);
      })
      .catch((e) => {
        console.error(e);
        alert(JSON.stringify(e));
      });
  },
};
