<template>
  <div class="layout-default d-flex flex-column">
    <component
      :is="componentHeader"
      v-model="isOpenMenu"
      :links="{
        anketa: linkToAnketa,
        landing: linkToLanding,
        lk: linkToLK,
      }"
      :profile="profile"
      :unread-count="unreadCount"
      :count="count"
      :is-login="isLoggedIn"
      :is-without-active="isWithoutActive"
      :to-color-name="toColorName"
      @clickLogout="onAuthLogout"
      @clickSupport="onShowSupportChat"
    >
      <button class="support-chat-btn" @click="onShowSupportChat">
        Поддержка
      </button>
    </component>
    <main
      v-show="!isOpenMenu"
      class="layout-default__main"
      :class="[
        $route.meta.layoutVCenter && 'flex-grow-1',
        'layout-col',
        {
          'p-4': theme == 'krediska',
        },
        {
          '_no-padding': $route.meta.layoutWithoutPadding,
        },
      ]"
    >
      <slot />
      <Snackbar
        v-if="isLoggedIn && isSnackbarTrafficShow && !isCloseSnackbar"
        @close="onCloseSnackbar"
      />
    </main>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import themeMixins from "@/mixins/theme.js";

function initSupportChat(d, w, m, i) {
  window.supportAPIMethod = m;
  const el = d.createElement("script");
  el.id = "supportScript";
  const id = "b77a865bbbf0b56607380375689a0cd3";
  el.src =
    (!i
      ? "https://admin.verbox.ru/support/support.js"
      : "https://static.site-chat.me/support/support.int.js") +
    "?h=" +
    id;
  el.onerror = i
    ? undefined
    : function () {
        initSupportChat(d, w, m, true);
      };
  w[m] = w[m]
    ? w[m]
    : function () {
        (w[m].q = w[m].q ? w[m].q : []).push(arguments);
      };
  (d.head ? d.head : d.body).appendChild(el);
}

export default {
  name: "LayoutDefault",
  mixins: [themeMixins],
  components: {
    Snackbar: defineAsyncComponent(() =>
      import("@/components/snackbar/snackbar.vue")
    ),
  },
  data() {
    return {
      isOpenMenu: false,
      snackbarPageCloseArr: [],
      isCloseSnackbar: false,
    };
  },
  computed: {
    ...mapState("auth", {
      profile: (state) => state.profile,
    }),
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn",
      isWithoutActive: "auth/isWithoutActive",
      unreadCount: "messages/unreadCount",
      count: "messages/countBoolean",
      isSnackbarTrafficShow: "auth/isSnackbarTrafficShow",
      clientInfoForVerbox: "auth/clientInfoForVerbox",
    }),
    linkToAnketa() {
      // eslint-disable-next-line no-undef
      return env.VUE_APP_ANKETA_URL;
    },
    linkToLanding() {
      // eslint-disable-next-line no-undef
      return env.VUE_APP_LANDING_URL;
    },
    linkToLK() {
      // eslint-disable-next-line no-undef
      return env.VUE_APP_LK_URL || "/";
    },
    componentHeader() {
      //header_krediska
      //import Header from "./components/header_beriberu/header.vue";
      return defineAsyncComponent(() =>
        import("./components/header_" + this.theme + "/header.vue")
      );
    },
  },
  watch: {
    "$route.name": {
      handler: function () {
        this.isCloseSnackbar = this.snackbarPageCloseArr.includes(
          this.$route.name
        );
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      authLogout: "auth/logout",
    }),
    ...mapMutations({
      setInfo: "auth/setInfo",
    }),
    onAuthLogout() {
      this.authLogout().then(() => {
        this.$router.push({ name: "Auth" });
        this.profile.name = "";
        this.setInfo(null);
      });
    },
    onCloseSnackbar() {
      const isPageClose = this.snackbarPageCloseArr.includes(this.$route.name);

      if (!isPageClose) {
        this.snackbarPageCloseArr.push(this.$route.name);
      }

      this.isCloseSnackbar = true;
    },
    onShowSupportChat() {
      if (!window.Verbox) {
        initSupportChat(document, window, "Verbox");
      }

      window.Verbox("setClientInfo", this.clientInfoForVerbox);
      window.Verbox("openSupport");
    },
  },
};
</script>

<style>
.layout-default {
  min-height: 100%;
}

@media (max-width: 768px) {
  .layout-default {
    padding-bottom: 200px;
  }
}

.layout-default__main {
  padding: 24px;
}

.layout-default__main._no-padding {
  padding: 0;
}

.support-chat-btn {
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: 10px;
  cursor: pointer;
}

.theme_dabank .button-next {
  display: block;
  position: relative;
}

.theme_dabank .button-next .icon-container {
  position: absolute;
  right: 30px;
}

.theme_hurma .popup-header {
  background-color: #f1f3f5;
}
</style>
